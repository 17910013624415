import { Box } from '@codecademy/gamut';
import { css, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const minProgressBarWith = 8;

const baseStyles = css({
  alignItems: 'center',
  height: '100%',
  display: 'flex',
  transitionDelay: '1.5s',
  transition: 'width 0.5s',
  position: 'absolute',
  borderRadius: 'inherit',
  borderColor: 'navy-800',
});

export const ExperienceBar = styled(motion.div)(
  baseStyles,
  css({
    borderWidth: '1px',
    borderStyle: 'solid',
  })
);

const foregroundeExperienceBarVariants = variant({
  defaultVariant: 'dashboard',
  prop: 'variant',
  base: {
    height: 'calc(100% - 2px)',
    bg: 'paleYellow',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
  },
  variants: {
    dashboard: {
      bg: 'yellow',
    },
    interstitial: {
      bg: 'paleYellow',
    },
    default: {},
  },
});

export const ForegroundExperienceBar = styled(Box)(
  baseStyles,
  foregroundeExperienceBarVariants
);

export const ExperienceBarWrapper = styled(Box)(
  css({
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    height: { _: '8px', sm: '18px' },
    borderRadius: { _: '80px', sm: '9px' },
  })
);
